<template>
  <v-card>
    <v-card-title>仕入先（編集）</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_code">仕入先ID<span style="color: red">*</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <span>{{ Form.sup_code }}</span>
            </v-col>
            <v-col cols="12" md="1">
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_name">仕入先名<span style="color: red">*</span></label>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="$v.Form.sup_name.$model"
                outlined
                dense
                :error-messages="supNameErrors"
                placeholder="仕入先名"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_name_abbr">仕入先略称<span style="color: red">*</span></label>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="$v.Form.sup_name_abbr.$model"
                outlined
                dense
                :error-messages="supNameAbbrErrors"
                placeholder="仕入先略称"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_zip">郵便番号</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_zip.$model"
                outlined
                dense
                :error-messages="supZipErrors"
                placeholder="郵便番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                outlined
                @click="getAddress(Form)"
              >
                <span>検索</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_address1">住所1</label>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="$v.Form.sup_address1.$model"
                :error-messages="supAdd1Errors"
                outlined
                dense
                placeholder="住所1（東京都台東区上野1-2-3）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_address2">住所2</label>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="$v.Form.sup_address2.$model"
                :error-messages="supAdd2Errors"
                outlined
                dense
                placeholder="住所２（住友ビル803）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_tel">固定電話番号</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_tel.$model"
                :error-messages="supTelErrors"
                outlined
                dense
                placeholder="固定電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_fax">FAX</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="$v.Form.sup_fax.$model"
                            :error-messages="supFaxErrors" outlined dense placeholder="FAX" hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_pic_name">担当者姓名</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_pic_name.$model"
                :error-messages="supPicNameErrors"
                outlined
                dense
                placeholder="担当者姓名（佐藤 一郎）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_pic_kana">担当者姓名（カナ）</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_pic_kana.$model"
                :error-messages="supPicKanaErrors"
                outlined
                dense
                placeholder="担当者姓名（サトウ イチロウ）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_pic_mobile">携帯電話番号</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_pic_mobile.$model"
                outlined
                :error-messages="supMoblieErrors"
                dense
                placeholder="携帯電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="2">
              <label for="sup_pic_email">E-mail</label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="$v.Form.sup_pic_email.$model"
                outlined
                :error-messages="supEmailErrors"
                dense
                placeholder="E-mail"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col offset-md="5" cols="12">
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(Form)"
              >
                Submit
              </v-btn>
              <v-btn class="mx-2" outlined @click="cancelClick">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions, mapMutations, } from 'vuex'
import { required, maxLength, numeric, email } from 'vuelidate/lib/validators'
/* eslint-disable */

export default {
  data: () => ({
    submitStatus: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      // sup_code: '',
      // sup_name: '',
      // sup_name_abbr: '',
      // sup_zip: '',
      // sup_address1: '',
      // sup_address2: '',
      // sup_tel: '',
      // sup_fax: '',
      // sup_pic_name: '',
      // sup_pic_kana: '',
      // sup_pic_mobile: '',
      // sup_pic_email: '',
      // prefcode:'',
    },
    changeFlag: false,
  }),
  validations: {
    Form: {
      // sup_code: {
      //   required,
      //   maxLength: maxLength(10),
      // },
      sup_name: {
        required,
        maxLength: maxLength(100),
      },
      sup_name_abbr: {
        required,
        maxLength: maxLength(45),
      },
      sup_zip: {
        numeric,
        maxLength: maxLength(7),
      },
      sup_address1: {
        maxLength: maxLength(100),
      },
      sup_address2: {
        maxLength: maxLength(100),
      },
      sup_tel: {
        numeric,
        maxLength: maxLength(10),
      },
      sup_fax: {
        numeric,
        maxLength: maxLength(10),
      },
      sup_pic_name: {
        maxLength: maxLength(20),
      },
      sup_pic_kana: {
        maxLength: maxLength(45),
      },
      sup_pic_mobile: {
        numeric,
        maxLength: maxLength(11),
      },
      sup_pic_email: {
        email,
        maxLength: maxLength(100),
      },
    },
  },
  created() {
    this.loadData()
  },
  destroyed() {
    this.clearSelectedSupplier()
  },
  computed: {
    ...mapState('supplierStore', ['selectedSupplier']),

    // supCodeErrors() {
    //   const errors = []
    //   if (!this.$v.Form.sup_code.$dirty) return errors
    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.Form.sup_code.maxLength && errors.push('10文字以内にしてください。')
    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.Form.sup_code.required && errors.push('必須項目')

    //   return errors
    // },
    supNameErrors() {
      const errors = []
      if (!this.$v.Form.sup_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_name.maxLength && errors.push('100文字以内にしてください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_name.required && errors.push('必須項目')

      return errors
    },
    supNameAbbrErrors() {
      const errors = []
      if (!this.$v.Form.sup_name_abbr.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_name_abbr.maxLength && errors.push('45文字以内にしてください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_name_abbr.required && errors.push('必須項目')

      return errors
    },
    supZipErrors() {
      const errors = []
      if (!this.$v.Form.sup_zip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_zip.maxLength && errors.push('7文字以内にしてください。')
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_zip.numeric && errors.push('必須数字')

      return errors
    },
    supAdd1Errors() {
      const errors = []
      if (!this.$v.Form.sup_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_address1.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
    supAdd2Errors() {
      const errors = []
      if (!this.$v.Form.sup_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_address2.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
    supTelErrors() {
      const errors = []

      if (!this.$v.Form.sup_tel.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_tel.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_tel.maxLength && errors.push('10数字以内にしてください。')

      return errors
    },
    supFaxErrors() {
      const errors = []

      if (!this.$v.Form.sup_fax.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_fax.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_fax.maxLength && errors.push('10数字以内にしてください。')

      return errors
    },
    supPicNameErrors() {
      const errors = []

      if (!this.$v.Form.sup_pic_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_name.maxLength && errors.push('20文字以内にしてください。')

      return errors
    },
    supPicKanaErrors() {
      const errors = []

      if (!this.$v.Form.sup_pic_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_kana.maxLength && errors.push('45文字以内にしてください。')

      return errors
    },
    supMoblieErrors() {
      const errors = []

      if (!this.$v.Form.sup_pic_mobile.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_mobile.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_mobile.maxLength && errors.push('11数字以内にしてください。')

      return errors
    },
    supEmailErrors() {
      const errors = []
      if (!this.$v.Form.sup_pic_email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_email.email && errors.push('正しくメールアドレスを入力してください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.sup_pic_email.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
  },

  watch:{
    Form: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('supplierStore', ['loadSupplier', 'editSupplier']),
    ...mapMutations('supplierStore', ['clearSelectedSupplier']),
    ...mapMutations('app', ['setOverlayStatus']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadSupplier(this.$route.params.id)
      .then(() => {
        // this.Form = {...this.selectedSupplier}
        this.Form = this.$deepCopy(this.selectedSupplier)
      })
      .catch(error=>{
        console.log(error.response)
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      })
      .finally(()=>{
        this.setOverlayStatus(false)
      })
    },

    jumpToListPage() {
      this.$router.push({ path: '/supplier-list' })
    },
    cancelClick() {
      if (!this.$isObjectEqual(this.Form, this.selectedSupplier)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    getAddress(Form) {
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const param = Form.sup_zip.replace('-', '') //数据类型有问题null
      const url = api + param

      fetch(url)
        .then(response => response.json())
        .then(data => {
          // if (data.status === 400) { // エラー時
          //   this.Form.sup_address1 = ''
          // } else 
          if (data.results === null) { // エラー時
            this.Form.sup_address1 = ''

            // this.Form.sup_address1 = '郵便番号から住所が見つかりませんでした。'
          } else {
            this.Form.prefcode = data.results[0].prefcode
            this.Form.sup_address1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
          }
        })
    },
    submitTo(Form) {
      // console.log('Form',Form)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editSupplier(Form)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(()=>{
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },

    submit(Form) {
      if (!this.$isObjectEqual(Form, this.selectedSupplier)) {
        this.submitTo(Form)
      } else {
        this.jumpToListPage()
      }
    }
  },
}
</script>
